import { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";

import "../css/styles.css";

import ScrollToTop from "../components/ScrollToTop";
import Route from "../components/SentryRoute";
import AppLoading from "../components/AppLoading";
import NotFound from "../components/NotFound";

const Account = lazy(() => import("./Account"));
const LoggedInRoutes = lazy(() => import("./LoggedInRoutes"));
const TermsConditions = lazy(() => import("../components/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../components/PrivacyPolicy"));
const CustomerServiceAgreement = lazy(
  () => import("../components/CustomerServiceAgreement"),
);

function App(props) {
  return (
    <Suspense fallback={<AppLoading />}>
      <>
        <ScrollToTop />
        <Switch>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsConditions />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/customer-service-agreement">
            <CustomerServiceAgreement />
          </Route>
          <Route path="/app">
            <ICRedirect />
          </Route>
          <Route path="/ic">
            <ICRedirect />
          </Route>
          <Route>
            <LoggedInRoutes />
          </Route>
        </Switch>
      </>
    </Suspense>
  );
}
App.propTypes = {};

/* Redirects old links (sometimes in old statements etc) */
function ICRedirect() {
  const re =
    /(?<matching_prefix>\/app\/customer|ic)\/(?<customer_id>\d+)(?<path>.*)/;
  const match = re.exec(window.location.pathname);
  if (!match) return <NotFound />;

  const newUrl = `/${match.groups.customer_id}${match.groups.path}${window.location.search}`;
  return <Redirect to={newUrl} />;
}

export default App;
