import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

import ModelBadge from "./ModelBadge";

const SecondaryNav = ({ children, className = "bg-dark px-3 mb-3" }) => {
  return (
    <div className={`d-print-none ${className}`}>
      <nav className="nav nav-underline">{children}</nav>
    </div>
  );
};
SecondaryNav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const SecondaryNavModelTitle = ({ to, modelTo, title, model }) => {
  return (
    <div className="d-flex align-items-center mr-2">
      <div>
        <Link to={modelTo}>
          <ModelBadge model={model} />
        </Link>
      </div>
      <h1 className="h5 mb-0">
        <NavLink className="nav-link" to={to}>
          {title || <span>&nbsp;</span>}
        </NavLink>
      </h1>
    </div>
  );
};
SecondaryNavModelTitle.propTypes = {
  to: PropTypes.string.isRequired,
  modelTo: PropTypes.string.isRequired,
  title: PropTypes.string,
  model: PropTypes.string,
};

export const SecondaryNavTitle = ({ title, model = null }) => (
  <div className="d-flex align-items-center">
    {model && (
      <div>
        <ModelBadge model={model} />
      </div>
    )}
    <h1 className="h5 nav-link mb-0 text-light">{title}</h1>
  </div>
);
SecondaryNavTitle.propTypes = {
  title: PropTypes.node,
  model: PropTypes.string,
};

export default SecondaryNav;
